<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        Parents meet summary
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="hideModal">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="12" class="ml-2">
                            <div>
                                <h5>
                                    <strong> Title: </strong> {{ parentsMeet.title }}
                                </h5>
                            </div>
                            
                            <div class="d-flex">
                                <h5 class="mt-3">
                                    <strong>Name: </strong> {{ parentsMeet.student_full_name }} {{ parentsMeet.last_name }}
                                </h5>
                                <h5 class="ml-40 mt-3">
                                    <strong>Class: </strong> {{ parentsMeet.class_title ? parentsMeet.class_title : 'NA' }}
                                </h5>
                            </div>

                            <div class="d-flex justify-space-between">
                                <h5 class="mt-3">
                                    <strong>Meeting type: </strong> {{ parentsMeet.meeting_type_text ? parentsMeet.meeting_type_text : 'NA'}}
                                </h5>
                            </div>

                            <div>
                                <h5 class="mt-3" v-if="parentsMeet.meeting_type == 'outside_premise'">
                                    <strong>Location:</strong> {{ parentsMeet.outside_premise_location }}
                                </h5>
                                <h5 class="mt-3" v-if="parentsMeet.meeting_type == 'online'">
                                    <strong>Meeting link: </strong>
                                    <a target="_blank" :href=parentsMeet.online_meeting_link>
                                        {{ parentsMeet.online_meeting_link }}
                                    </a>
                                </h5>
                            </div>
                            
                            
                            <div class="d-flex justify-space-between">
                                <h5 class="mt-3">
                                    <strong>Requested date: </strong> {{ parentsMeet.datetime_requested_to_visit_formatted ? parentsMeet.datetime_requested_to_visit_formatted : 'NA'}}
                                </h5>
                            </div>

                            <h5 class="mt-3 border p-3">
                                <strong>Visit request reason: </strong> 
                                <div class="mt-2" v-html="parentsMeet.request_for_visit_reason"></div>
                            </h5>

                            <div class="d-flex">
                                <h5 class="mt-3">
                                    <strong>Status: </strong> {{ parentsMeet.status_text ? parentsMeet.status_text : 'NA'}}
                                </h5>
                            </div>

                            <h5 class="mt-3" v-if="parentsMeet.status == 'attended'">
                                <strong>Date of visit: </strong>
                                 {{ parentsMeet.datetime_of_visit_formatted }}
                            </h5>

                            <h5 class="mt-3 border p-3" v-if="parentsMeet.status == 'attended'">
                                <strong>Parent comments: </strong> 
                                <div class="mt-2" v-if="parentsMeet.parent_comments" v-html="parentsMeet.parent_comments"></div>
                                <div v-else>NA</div>
                            </h5>

                            <h5 class="mt-3 border p-3" v-if="parentsMeet.status == 'attended'">
                                <strong>Attendee comments: </strong> 
                                <div class="mt-2" v-if="parentsMeet.attendee_comments" v-html="parentsMeet.attendee_comments"></div>
                                <div v-else>NA</div>
                            </h5>

                            <h5 class="mt-3 border p-3" v-if="parentsMeet.status == 'not_attended'">
                                <strong>Reason: </strong> 
                                <div class="mt-2" v-if="parentsMeet.not_attended_reason" v-html="parentsMeet.not_attended_reason"></div>
                                <div v-else>NA</div>
                            </h5>

                        </v-col>
                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn 
                                    depressed 
                                    @click="hideModal" 
                                    class="text-gray btn btn-standard
                                ">
                                    Close
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import StudentParentsVisitLogsService from "@/core/services/student-parents-visit-log/StudentParentsVisitLogsService";

const parentsMeet = new StudentParentsVisitLogsService();

export default {
    data() {
        return {
            parentsMeet: '',
            dialog: false
        };
    },
    methods: {
        showModal(parentsMeet) {
            this.parentsMeet = parentsMeet;
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
        }
    }
}
</script>
