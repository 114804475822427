<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                            <h4> Parents meet</h4>
                            <div class="breadcrumb-sub-header">
                                <router-link to="/dashboard">Dashboard</router-link>
                                \ Parents meet
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="10" sm="6" md="3">
                                    <v-text-field 
                                        class="form-control" 
                                        v-model="search.title"
                                        label="Search by title" 
                                        outlined 
                                        clearable
                                        v-on:keyup.enter="getParentMeets()" dense>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-select
                                        outlined
                                        dense
                                        @change="handleDateFilterTypeChange"
                                        v-model="search.date_filter_type"
                                        :items="dateFilterTypes"
                                        item-text="title"
                                        item-value="value"
                                        label="Select date type"
                                        clearable
                                        @input="search.date_filter_type = $event !== null ? $event : ''" 
                                    ></v-select>
                                </v-col>

                                <v-col cols="10" sm="6" md="2" v-if="search.date_filter_type == 'custom'">
                                        <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            min-width="auto"
                                            offset-y
                                            transition="scale-transition"
                                            v-model="menu"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    append-icon="mdi-calendar"
                                                    clearable
                                                    dense
                                                    label="Date"
                                                    outlined
                                                    readonly 
                                                    v-bind="attrs"
                                                    v-model="search.date"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                @input="menu = false"
                                                v-model="search.date"
                                            ></v-date-picker>
                                        </v-menu>
                                </v-col>
                              
                                <v-col cols="10" sm="6" :md="search.date_filter_type == 'custom' ? 2 : 3">
                                    <v-select class="form-control" 
                                        v-model="search.status"
                                        label="Status" 
                                        outlined
                                        :items="statuses"
                                        item-text="title"
                                        item-value="value" 
                                        clearable
                                        v-on:keyup.enter="getParentMeets()" 
                                        dense
                                    >
                                    </v-select>
                                </v-col>
                                
                                <v-col cols="2" md="2">
                                    <v-btn :loading="loading" @click.prevent="searchParentMeets()"
                                        class="btn btn-primary w-35" dark>
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <v-skeleton-loader
                                v-if="loading"
                                type="table-thead"
                            >
                            </v-skeleton-loader>

                            <v-skeleton-loader
                                v-if="loading"
                                type="table-row-divider@25"
                            >
                            </v-skeleton-loader>

                            <table class="table" v-if="!loading">
                                <thead>
                                    <tr class="px-3">
                                        <th class="px-3 wrap-column">Title</th>
                                        <th class="px-3 wrap-column">Reason</th>
                                        <th class="px-3 wrap-column">Requested visit date</th>
                                        <th class="px-3 wrap-column">Status</th>
                                        <th class="pr-3 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="parentMeets.length > 0" v-for="(item, index) of parentMeets" :key="item.id">
                                        <td class="px-3 wrap-column">
                                            <div><strong> {{ item.title }} </strong> </div>
                                            <div v-if="item.meeting_type">
                                                <small>Meeting type: {{ item.meeting_type_text }}</small>
                                                
                                                <div v-if="item.meeting_type == 'online'">
                                                    <small>
                                                        Meeting link : 
                                                        <a :href="item.online_meeting_link" target="_blank">
                                                            {{ item.online_meeting_link }}
                                                        </a>
                                                    </small>
                                                </div>

                                                <div v-if="item.meeting_type == 'outside_premise'">
                                                    <small>
                                                        Meeting link : {{ item.outside_premise_location }}
                                                    </small>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-3 wrap-column">
                                            <div v-if="item.request_for_visit_reason" v-html="item.request_for_visit_reason"></div>
                                            <div v-else>NA</div>
                                        </td>
                                        <td class="px-3 wrap-column">
                                            <div>
                                                {{ item.datetime_requested_to_visit_formatted ? item.datetime_requested_to_visit_formatted : 'NA'}}
                                            </div>
                                        </td>
                                        
                                        <td class="px-3 pt-4 wrap-column">
                                            <span class="badge text-lg" :class="item.status =='not_attended' ? 'badge-danger':'badge-success'">
                                                {{ item.status_text }}
                                            </span>
                                            <div class="mt-2" v-if="item.status == 'not_attended'">
                                                <strong>Reason :</strong>
                                                <span v-html="item.not_attended_reason"></span>
                                            </div>
                                        </td>
                                        <td class="pr-0 text-center">
                                            <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a href="#" class="navi-link" @click="summary(item)">
                                                            <span class="navi-icon">
                                                                <i class="fa fa-eye"></i>
                                                            </span>
                                                            <span class="navi-text"> Summary </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr v-if="parentMeets.length == 0">
                                        <td class="text-center  px-3" colspan="6">No items found</td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination 
                                v-show="parentMeets.length > 0" 
                                @input="getParentMeets" 
                                class="pull-right mt-7" 
                                v-model="page"
                                :total-rows="total" 
                                :per-page="perPage" 
                                first-number 
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <show @refresh="getParentMeets" ref="summary"></show>
    </v-app>
</template>

<script>
    import StudentParentsVisitLogsService from "@/core/services/student-parents-visit-log/StudentParentsVisitLogsService";
    import Show from "./Show.vue";

    const parentsMeet = new StudentParentsVisitLogsService();

    export default {
        components:{
            Show
        },  
        data(){
            return {
                loading: false,
                parentMeets: [],
                search:{
                    title: '',
                    date_filter_type: '',
                    date: '',
                    status: ''
                },
                menu: false,
                page: null,
                perPage: null,
                total: null,
                dateFilterTypes:[
                    {
                        title: 'Today',
                        value: 'today'
                    },
                    {
                        title: 'Upcoming',
                        value: 'upcoming'
                    },
                    {
                        title: 'Previous',
                        value: 'past'
                    },
                    {
                        title: 'Custom',
                        value: 'custom'
                    },
                ],
                statuses:[
                    {
                        title: 'Requested',
                        value: 'requested'
                    },
                    {
                        title: 'Attended',
                        value: 'attended'
                    },
                    {
                        title: 'Not Attended',
                        value: 'not_attended'
                    }
                ]
            }
        },
        methods:{
            searchParentMeets(){
                this.page = 1;
                this.getParentMeets();
            },  
            getParentMeets() {
                this.loading = true;
                parentsMeet
                    .paginate(this.search, this.page)
                    .then(response => {
                        this.parentMeets = response.data.data;
                        this.page = response.data.meta.current_page;
                        this.total = response.data.meta.total;
                        this.perPage = response.data.meta.per_page;
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                    });
            },
            handleDateFilterTypeChange(){
                if(this.search.date_filter_type == 'custom'){
                    this.search.date = null;
                }
            },
           
            summary(item){
                this.$refs["summary"].showModal(item);
            }
        },
        mounted(){
            this.getParentMeets();
        }
    }
</script>

<style scoped>
    .wrap-column{
        max-width: 270px !important; 
        white-space: pre-wrap;
    }
</style>